import { Box, Text } from "@chakra-ui/layout"
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Box minHeight="100vh" d="flex" justifyContent="center" alignItems="center">
      <Box h="100%" display="flex">
        <Box h="100%" textAlign="center">
          <Text as="h1" fontSize="5xl" fontWeight="bold">
            404
          </Text>
          <Box mt="2">Die gewünschte Seite exisitiert leider nicht.</Box>
          <Box mt="4">The site you are looking for does not exist</Box>
        </Box>
      </Box>
    </Box>
  </Layout>
)

export default NotFoundPage
